import React, { useEffect, useState } from "react";
import { Container, Tooltip, OverlayTrigger } from "react-bootstrap";
import Products from "./Products";
import Contact from "./Contact";
import AboutUs from "./About";
import Pricing from "./Pricing";

import "bootstrap/dist/css/bootstrap.min.css";
import "./Home.css";

const Home = ({ incomingSelectedSection }) => {
  const [selectedSection, setSelectedSection] = useState("");

  useEffect(() => {
    if (incomingSelectedSection) {
      const [section, product] = incomingSelectedSection.split("&");
      setSelectedSection(section);
    }
  }, [incomingSelectedSection]);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Pronounced "/Rhea-sirk/"
    </Tooltip>
  );

  const renderContent = () => {
    switch (selectedSection) {
      case "products":
        return <Products incomingSelectedSection={selectedSection} />;
      case "contact":
        return <Contact />;
      case "about":
        return <AboutUs />;
      case "pricing":
        return <Pricing />;
      default:
        return (
          <>
            <Products />
          </>
        );
    }
  };

  return (
    <>
      <section id="intro" className="section text-center">
        <Container>
          <OverlayTrigger placement="top" overlay={renderTooltip}>
            <h1>
              <img
                src="/img/reacirc-logo.png"
                alt="REACirc Logo"
                className="logo"
              />
              {"   "}
              Welcome to <span className="tooltip-text">REACirc</span>
              <img
                src="/img/reacirc-logo.png"
                alt="REACirc Logo"
                className="logo"
              />
            </h1>
          </OverlayTrigger>
          <p className="intro-description">
            At <strong>REACirc</strong>, we're all about changing how real
            estate professionals connect and work together.
          </p>
          <p>
            REACirc is custom-designed to meet the diverse needs of the real
            estate industry.
          </p>
        </Container>
      </section>
      {renderContent()}
    </>
  );
};

export default Home;

import React, { useEffect, useState, useRef } from "react";
import { Container, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import { QRCodeSVG } from "qrcode.react";
import { faQrcode } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faUser,
  faCalendarDay,
} from "@fortawesome/free-solid-svg-icons";

import "bootstrap/dist/css/bootstrap.min.css";
import "./Products.css";
import "./Brokerages.css";
import "./Agents.css";
import "./Broker.css";
import "./NSC2024.css";
import "./Conferences.css";

import nsc2024Logo from "../assets/img/nsc2024-logo.png";

import Brokerages from "./Brokerages";
import Agents from "./Agents";
import Broker from "./Broker";
import Conferences from "./Conferences";
import NSC2024 from "./NSC2024";
import AboutUs from "./About";

const Products = ({ incomingSelectedSection }) => {
  const [selectedSection, setSelectedSection] = useState(() => {
    // Initialize from localStorage or use incomingSelectedSection or default to empty string
    return (
      localStorage.getItem("selectedSection") || incomingSelectedSection || ""
    );
  });
  const [email, setEmail] = useState("");
  const [formError, setFormError] = useState(null);
  const [params] = useState(new URLSearchParams(window.location.search));
  const [showQR, setShowQR] = useState(false);
  const [showQRCode, setShowQRCode] = useState(false);

  const sectionRefs = {
    nsc2024: useRef(null),
    brokerages: useRef(null),
    agents: useRef(null),
    broker: useRef(null),
  };

  useEffect(() => {
    if (incomingSelectedSection) {
      setSelectedSection(incomingSelectedSection);
      localStorage.setItem("selectedSection", incomingSelectedSection);
    }
  }, [incomingSelectedSection]);

  useEffect(() => {
    const qr = params.get("QR");
    setShowQR(qr === "true");
  }, [params]);

  const [QRCodeValue, setQRCodeValue] = useState("");

  useEffect(() => {
    const baseUrl = window.location.origin + window.location.pathname;
    const newParams = new URLSearchParams({
      section: selectedSection,
      QR: "true",
    });
    const fullUrl = `${baseUrl}?${newParams.toString()}`;
    setQRCodeValue(fullUrl);
  }, [selectedSection, QRCodeValue]);

  useEffect(() => {
    const newParams = new URLSearchParams(window.location.search);
    newParams.set("section", selectedSection);
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${newParams}`
    );
    // Update localStorage when selectedSection changes
    localStorage.setItem("selectedSection", selectedSection);
  }, [selectedSection]);

  useEffect(() => {
    if (selectedSection && sectionRefs[selectedSection]?.current) {
      sectionRefs[selectedSection].current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [selectedSection]);

  const handleEmailChange = (e) => setEmail(e.target.value);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("/register", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams({ email }),
      });

      if (!response.ok) throw new Error("Failed to register");

      window.location.href = "/success.html";
    } catch (error) {
      setFormError("Registration failed, please try again.");
    }
  };

  const handleSelection = (section) => {
    setSelectedSection(section);
    localStorage.setItem("selectedSection", section);

    const newParams = new URLSearchParams(window.location.search);
    newParams.set("section", section);
    const newUrl = `${window.location.pathname}?${newParams.toString()}`;
    window.history.pushState({}, "", newUrl);
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Pronounced "/Rhea-sirk/"
    </Tooltip>
  );

  return (
    <>
      {showQR && (
        <section id="qr-toggle" className="section text-center">
          <Container>
            <Button
              variant="primary"
              onClick={() => setShowQRCode(!showQRCode)}
              className="qr-toggle-button"
            >
              <FontAwesomeIcon icon={faQrcode} className="me-2" />
              {showQRCode ? " Hide QR Code" : " Show QR Code"}
            </Button>
          </Container>
        </section>
      )}

      <section id="intro" className="section text-center">
        <Container>
          <p className="intro-highlight">
            <strong>
              Choose the option below that aligns best with your needs
            </strong>
            <br />
            and discover how REACirc can enhance your professional journey:
          </p>
        </Container>
      </section>

      <section id="toggle-menu" className="section text-center">
        <Container>
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="btn-group btn-group-responsive">
                <Button
                  variant="primary"
                  className={`option-button nsc2024-button ${
                    selectedSection === "nsc2024" ? "active" : ""
                  }`}
                  onClick={() => handleSelection("nsc2024")}
                >
                  <div className="nscLogo">
                    <img
                      src={nsc2024Logo}
                      style={{ width: "150px" }}
                      alt="NSC 2024 Logo"
                    />
                  </div>
                  <div className="option-text-box">
                    <div className="option-text">
                      <h2>REACirc for NSC 2024</h2>
                      <p>
                        FREE: Maximize your NSC 2024 Experience with REACirc.
                      </p>
                    </div>
                  </div>
                </Button>

                <Button
                  variant="primary"
                  className={`option-button brokerages-button ${
                    selectedSection === "brokerages" ? "active" : ""
                  }`}
                  onClick={() => handleSelection("brokerages")}
                >
                  <div className="option-icon">
                    <FontAwesomeIcon icon={faBuilding} />
                  </div>
                  <div className="option-text-box">
                    <div className="option-text">
                      <h2>REACirc for Brokerages</h2>
                      <p>
                        Empower your brokerage with integrated tools for
                        communication and collaboration.
                      </p>
                    </div>
                  </div>
                </Button>

                <Button
                  variant="primary"
                  className={`option-button agents-button ${
                    selectedSection === "agents" ? "active" : ""
                  }`}
                  onClick={() => handleSelection("agents")}
                >
                  <div className="option-icon">
                    <FontAwesomeIcon icon={faUser} />
                  </div>
                  <div className="option-text-box">
                    <div className="option-text">
                      <h2>REACirc for Agents</h2>
                      <p>
                        Connect with agents across North America to share
                        valuable ideas and insights.
                      </p>
                    </div>
                  </div>
                </Button>

                <Button
                  variant="primary"
                  className={`option-button conferences-button ${
                    selectedSection === "broker" ? "active" : ""
                  }`}
                  onClick={() => handleSelection("broker")}
                >
                  <div className="option-icon">
                    <FontAwesomeIcon icon={faCalendarDay} />
                  </div>
                  <div className="option-text-box">
                    <div className="option-text">
                      <h2>REACirc for Broker Owners/Managers</h2>
                      <p>
                        Connect with fellow broker owners and managers to share
                        insights and strategies.
                      </p>
                    </div>
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </Container>
      </section>

      <section id="content" className="section">
        <Container>
          <div className="row justify-content-center">
            {selectedSection === "nsc2024" && (
              <div ref={sectionRefs.nsc2024}>
                <NSC2024
                  id="nsc2024"
                  email={email}
                  handleEmailChange={handleEmailChange}
                  handleFormSubmit={handleFormSubmit}
                  formError={formError}
                />
              </div>
            )}
            {selectedSection === "brokerages" && (
              <div ref={sectionRefs.brokerages}>
                <Brokerages
                  id="brokerages"
                  email={email}
                  handleEmailChange={handleEmailChange}
                  handleFormSubmit={handleFormSubmit}
                  formError={formError}
                />
              </div>
            )}
            {selectedSection === "agents" && (
              <div ref={sectionRefs.agents}>
                <Agents
                  id="agents"
                  email={email}
                  handleEmailChange={handleEmailChange}
                  handleFormSubmit={handleFormSubmit}
                  formError={formError}
                />
              </div>
            )}
            {selectedSection === "broker" && (
              <div ref={sectionRefs.broker}>
                <Broker
                  id="broker"
                  email={email}
                  handleEmailChange={handleEmailChange}
                  handleFormSubmit={handleFormSubmit}
                  formError={formError}
                />
              </div>
            )}
            {selectedSection === "conferences" && (
              <Conferences
                email={email}
                handleEmailChange={handleEmailChange}
                handleFormSubmit={handleFormSubmit}
                formError={formError}
              />
            )}
            {selectedSection === "about" && <AboutUs />}
          </div>
        </Container>
      </section>

      {showQRCode && (
        <div className="qr-code-container">
          <QRCodeSVG
            value={window.location.href}
            size={256}
            bgColor="#FFFFFF"
            fgColor="#000000"
            level="H"
            includeMargin={true}
            imageSettings={{
              src: "/img/reacirc-logo.png",
              x: undefined,
              y: undefined,
              height: 24,
              width: 24,
              excavate: true,
            }}
          />
          <Button
            variant="primary"
            onClick={() => {
              const svg = document.querySelector(".qr-code-container svg");
              const svgData = new XMLSerializer().serializeToString(svg);
              const canvas = document.createElement("canvas");
              const ctx = canvas.getContext("2d");
              const img = new Image();
              img.onload = () => {
                const dpi = 300;
                canvas.width = 10 * dpi;
                canvas.height = 10 * dpi;

                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                const logo = new Image();
                logo.onload = () => {
                  const logoSize = Math.min(canvas.width, canvas.height) / 4;
                  const logoX = (canvas.width - logoSize) / 2;
                  const logoY = (canvas.height - logoSize) / 2;

                  ctx.drawImage(logo, logoX, logoY, logoSize, logoSize);

                  const pngFile = canvas.toDataURL("image/png");
                  const downloadLink = document.createElement("a");
                  downloadLink.download = "qr-code-with-logo.png";
                  downloadLink.href = pngFile;
                  downloadLink.click();
                };
                logo.src = "/img/reacirc-logo.png";
              };
              img.src = "data:image/svg+xml;base64," + btoa(svgData);
            }}
          >
            Download QR Code
          </Button>
        </div>
      )}
    </>
  );
};

export default Products;

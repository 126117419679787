import React from "react";
import { Container, Typography, Box } from "@mui/material";

const DPA = () => {
  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h3" component="h1" gutterBottom>
          Data Processing Addendum
        </Typography>

        <Typography variant="body1" paragraph>
          This Data Processing Addendum ("DPA") supplements the agreement between RealtyOS Inc. ("REACirc") and the customer that agrees (or the customer on whose behalf an Administrator agrees) to the Platform Terms of Service (the "Customer") in relation to the transfer and processing of Covered Data in connection with the performance of the Services.
        </Typography>

        <Typography variant="h6" gutterBottom>
          1. Definitions
        </Typography>
        <Typography variant="body1" paragraph>
          Capitalized terms used but not defined within this DPA will have the meaning set forth in the REACirc Terms of Service. Key terms like "Applicable Data Protection Laws", "CCPA", "Covered Data", "Personal Data", "Processing", and others are defined in detail in the full DPA document.
        </Typography>

        <Typography variant="h6" gutterBottom>
          2. Role of the Parties
        </Typography>
        <Typography variant="body1" paragraph>
          The Parties acknowledge that REACirc acts as a processor or service provider in the performance of its obligations under the Agreement and this DPA, and Customer acts as a controller or business, with certain exceptions for specific data types.
        </Typography>

        <Typography variant="h6" gutterBottom>
          3. Details of Data Processing
        </Typography>
        <Typography variant="body1" paragraph>
          The details of the Processing of Personal Data under the Agreement and this DPA are described in Schedule 1 of the full DPA document.
        </Typography>

        <Typography variant="h6" gutterBottom>
          4. Sub-processors
        </Typography>
        <Typography variant="body1" paragraph>
          REACirc may engage Sub-processors to Process Covered Data, subject to the terms outlined in the full DPA document.
        </Typography>

        <Typography variant="h6" gutterBottom>
          5. Data Subject Rights
        </Typography>
        <Typography variant="body1" paragraph>
          REACirc will assist Customer in responding to requests from Data Subjects to exercise their rights under Applicable Data Protection Laws.
        </Typography>

        <Typography variant="h6" gutterBottom>
          6. Security
        </Typography>
        <Typography variant="body1" paragraph>
          REACirc will implement and maintain appropriate technical and organizational data protection and security measures as outlined in Schedule 2 of the full DPA document.
        </Typography>

        <Typography variant="h6" gutterBottom>
          7. Security Incidents
        </Typography>
        <Typography variant="body1" paragraph>
          REACirc shall notify Customer without undue delay after becoming aware of any Security Incident and take reasonable steps to mitigate and investigate such incidents.
        </Typography>

        <Typography variant="h6" gutterBottom>
          8. Standard Contractual Clauses
        </Typography>
        <Typography variant="body1" paragraph>
          The Standard Contractual Clauses shall apply to certain transfers of Covered Data, as detailed in Schedule 3 of the full DPA document.
        </Typography>

        <Typography variant="body1" paragraph>
          For the full Data Processing Addendum, including all schedules and detailed provisions, please contact us at <a href="mailto:privacy@reacirc.com">privacy@reacirc.com</a>.
        </Typography>
      </Box>
    </Container>
  );
};

export default DPA;

import { React, useState, useEffect } from 'react';
import { Container, Button, Form, Modal } from 'react-bootstrap';
import { submitUserRequest } from '../services/userService';
import Creatable from 'react-select/creatable'; // Import Creatable from react-select
import { provinces, citiesByProvince } from '../data/canadianCities'; // Import data
import Select from 'react-select'; // Import react-select

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/free-solid-svg-icons'; // Example icons
import {
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Select as MuiSelect,
  Box,
} from '@mui/material';

const Broker = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    city: '',
    province: '',
    brokerage: '',
    email: '',
    mobileNumber: '',
    photo: null,
    brokerRole: '',
    promoCode: '',
    type: 'REACirc-BOM',
    password: '', // Add password here
    passwordVerify: '',
  });
  const [formError, setFormError] = useState('');
  const [errors, setErrors] = useState({ mobileNumber: '', photo: '' }); // Add photo error here

  const [modalShow, setModalShow] = useState(false); // State to manage modal visibility
  const [successModalShow, setSuccessModalShow] = useState(false); // State to manage success modal visibility
  const [photoPreview, setPhotoPreview] = useState(''); // State for image preview
  const [cityOptions, setCityOptions] = useState([]);
  const [provinceOptions] = useState(provinces);

  // Update city options based on selected province
  useEffect(() => {
    if (formData.province) {
      const cities = citiesByProvince[formData.province] || [];
      // Remove duplicates

      const uniqueCities = Array.from(
        new Map(cities.map((city) => [city.value, city])).values()
      );

      const sortedCities = uniqueCities.sort((a, b) =>
        a.label.localeCompare(b.label)
      );
      setCityOptions(sortedCities);
    } else {
      setCityOptions([]);
    }
  }, [formData.province]);

  useEffect(() => {
    if (formData.password && formData.passwordVerify) {
      if (formData.password === formData.passwordVerify) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          password: '',
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          password: "Passwords Don't Match",
        }));
      }
    } else {
      // If either field is empty, don't show the error
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: '',
      }));
    }
  }, [formData.password, formData.passwordVerify]);

  // Phone number formatting function
  const formatPhoneNumber = (value) => {
    const cleaned = ('' + value).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return value;
  };

  // Handle input changes
  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    // Validate field on change
    if (name === 'mobileNumber') {
      const error = validatePhoneNumber(value);
      setErrors({ ...errors, [name]: error });
    }

    if (type === 'file') {
      const file = files[0];
      if (file) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: file,
        }));

        // Create a file reader to generate a preview
        const reader = new FileReader();
        reader.onloadend = () => {
          setPhotoPreview(reader.result);
        };
        reader.readAsDataURL(file);
      }
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: name === 'mobileNumber' ? formatPhoneNumber(value) : value,
      }));
    }
  };

  const validatePhoneNumber = (value) => {
    // North American phone number pattern
    const phonePattern =
      /^(?:\+1\s?)?(?:\(\d{3}\)|\d{3})[-.\s]?\d{3}[-.\s]?\d{4}$/;
    return phonePattern.test(value) ? '' : 'Invalid phone number format.';
  };

  // Handle select changes
  const handleSelectChange = (selectedOption, actionMeta) => {
    setFormData({
      ...formData,
      [actionMeta.name]: selectedOption ? selectedOption.value : '',
    });
  };

  // Handle city input changes
  const handleCityChange = (newValue) => {
    setFormData({
      ...formData,
      city: newValue ? newValue.value : '',
    });
  };
  // Function to format phone number
  const cleanPhoneNumber = (phoneNumber) => {
    return phoneNumber.replace(/\D/g, ''); // Remove all non-numeric characters
  };

  const validatePasswords = () => {
    if (formData.password !== formData.passwordVerify) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: "Passwords Don't Match",
      }));
      return false;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: '',
      }));
      return true;
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!validatePasswords()) {
      return;
    }

    if (!formData.photo) {
      setFormError('Please upload a headshot.');
      return;
    }

    try {
      const cleanedPhoneNumber = cleanPhoneNumber(formData.mobileNumber);
      const data = {
        ...formData,
        mobileNumber: cleanedPhoneNumber,
      };
      await submitUserRequest(data);

      setFormError('');
      setModalShow(false); // Close the form modal on successful submit
      setSuccessModalShow(true); // Show the success modal
    } catch (error) {
      setErrors({ ...errors, form: error.message });
    }
  };

  const handleShow = () => setModalShow(true); // Show the form modal
  const handleClose = () => setModalShow(false); // Hide the form modal
  const handleSuccessClose = () => setSuccessModalShow(false); // Hide the success modal

  // Sort cityOptions alphabetically by the label
  const sortedCityOptions = [...cityOptions].sort((a, b) =>
    a.label.localeCompare(b.label)
  );

  return (
    <>
      <section id='hero' className='section broker_bg__hero'>
        <Container>
          <Grid className='row justify-content-center text-center'>
            <Grid className='col-md-10'>
              <Box className='option-icon-broker'>
                <FontAwesomeIcon icon={faBuilding} />
              </Box>
              <Typography variant='h1'>
                Elevate Your Brokerage with <br />
                <span className='highlight--blue'>REACirc Broker Connect!</span>
              </Typography>
              <Typography variant='h6' sx={{ fontSize: '1.5rem' }}>
                <strong>REACirc for Broker Owners and Managers</strong>
              </Typography>

              <Typography variant='h2'>
                Your exclusive platform for connecting with fellow broker owners
                and managers. Share business insights, best practices, and
                market activity while engaging in high-level mastermind sessions
                to drive your brokerage's success.
              </Typography>

              <Typography
                variant='h2'
                align='center'
                sx={{
                  marginTop: '0px',
                  fontStyle: 'italic',
                  fontSize: '1.0rem',
                }}
              >
                Leveraging decades of real estate leadership experience, REACirc
                Broker Connect is tailored to address the unique challenges and
                opportunities faced by brokerage owners and managers.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </section>
      <section id='sectionOffset'>
        <Container>
          <Grid className='row justify-content-center'>
            <Grid className='col-lg-10 bordered-list'>
              <Box className='block__list'>
                <div className='text-center mb-4'>
                  <Typography
                    variant='h3'
                    sx={{ fontSize: '2rem', fontWeight: 'bolder' }}
                    className='text-center mb-4'
                  >
                    Unlock the Power of Broker-to-Broker Collaboration with
                    REACirc
                  </Typography>
                  <ul className='icon__check--blue mb-5'>
                    <li>
                      <strong>Exclusive Broker Network:</strong>
                      <br />
                      Connect with top broker owners and managers nationwide,
                      sharing strategies, insights, and fostering partnerships
                      that drive growth and innovation in your brokerage.
                    </li>
                    <br />
                    <li>
                      <strong>High-Level Mastermind Sessions:</strong>
                      <br />
                      Participate in mastermind groups focused on solving
                      complex business challenges, identifying market
                      opportunities, and developing cutting-edge brokerage
                      strategies.
                    </li>
                    <br />
                    <li>
                      <strong>Business Intelligence Exchange:</strong>
                      <br />
                      Gain access to proprietary market data, industry trends,
                      and performance benchmarks to inform your decision-making
                      and stay ahead of the competition.
                    </li>
                    <br />
                    <li>
                      <strong>Best Practice Sharing:</strong>
                      <br />
                      Learn from and contribute to a curated repository of
                      brokerage best practices, covering operations,
                      recruitment, retention, technology adoption, and more.
                    </li>
                    <br />
                    <li>
                      <strong>Strategic Partnership Opportunities:</strong>
                      <br />
                      Explore potential partnerships, mergers, or acquisitions
                      with other forward-thinking brokerages to expand your
                      market presence and capabilities.
                    </li>
                  </ul>
                  <Box
                    sx={{
                      padding: '2rem',
                      backgroundColor: '#f9f9f9',
                      borderRadius: '8px',
                      boxShadow: 3,
                    }}
                  >
                    <Typography
                      variant='h4'
                      sx={{ fontWeight: 'bold', marginBottom: '0.5rem' }}
                    >
                      For Forward-Thinking Brokerage Leaders
                    </Typography>
                    <Typography
                      sx={{ marginBottom: '1.5rem', fontStyle: 'italic' }}
                    >
                      Accelerate Your Growth with Cutting-Edge Strategies and
                      Connections
                    </Typography>

                    <Box
                      sx={{
                        marginBottom: '1.5rem',
                        padding: '1rem',
                        backgroundColor: '#ffffff',
                        borderRadius: '8px',
                        boxShadow: 1,
                      }}
                    >
                      <Typography
                        variant='h6'
                        sx={{ fontWeight: 'bold', marginBottom: '0.5rem' }}
                      >
                        Transform Your Real Estate Business with Exclusive
                        Access to REACirc
                      </Typography>
                      <Typography>
                        As a broker owner or manager, you gain complimentary
                        access to REACirc, a premium platform designed for the
                        elite few who are serious about taking their real estate
                        business to the next level. This isn't just a tool; it's
                        a game-changing opportunity for your brokerage's growth
                        and success.
                      </Typography>
                      <Typography
                        variant='h6'
                        sx={{ m: '1rem', fontWeight: 'bolder' }}
                      >
                        Why REACirc is for the Serious Professional:
                      </Typography>
                      <ul className='icon__check--blue mb-3'>
                        <li>
                          <strong>Unmatched Value:</strong>
                          {'  '}
                          With REACirc, you're not just gaining a service—you're
                          accessing a premium network that will propel your
                          brokerage to new heights of success and profitability.
                        </li>
                        <li>
                          <strong>Exclusive Community:</strong>
                          {'  '}
                          Join an exclusive network of broker owners and
                          managers who are committed to elevating their
                          brokerages, driving innovation, and maximizing their
                          leadership potential.
                        </li>
                        <li>
                          <strong>Strategic Advantage:</strong> {'  '}Tap into
                          advanced networking and collaboration features that
                          give you a competitive edge in the real estate market.
                        </li>
                      </ul>
                      This opportunity is exclusively for broker owners and
                      managers like you. It's a complimentary access to an elite
                      platform designed for serious professionals who are ready
                      to elevate their brokerage to new heights. <br />
                      <br />
                      If you're ready to make a significant impact in your
                      industry, REACirc is your key to unlocking unparalleled
                      success.
                    </Box>

                    <>
                      <Box
                        sx={{
                          padding: '1rem',
                          backgroundColor: '#fffae6',
                          borderRadius: '8px',
                          border: '2px solid #ffcc00',
                        }}
                      >
                        <Typography
                          variant='h4'
                          sx={{
                            fontWeight: 'bold',
                            marginBottom: '0.5rem',
                            color: '#ff6600',
                          }}
                        >
                          Exclusive Opportunity:
                        </Typography>
                        <Typography sx={{ marginBottom: '1rem' }}>
                          REACirc is offering this premium service to start for
                          just <strong>50</strong> broker owners and managers.
                          This exclusive opportunity is designed for industry
                          leaders ready to invest seriously in their brokerage's
                          growth, networking, and market dominance. <br />
                          <br />
                        </Typography>
                        <Typography>
                          Be among the first to sign up and enjoy exclusive
                          benefits. Secure your spot today!
                        </Typography>
                      </Box>
                    </>

                    <Button
                      variant='contained'
                      color='primary'
                      onClick={handleShow}
                      style={{
                        backgroundColor: '#FFD700', // Yellow color
                        color: '#000', // Text color (black)
                        width: '100%', // Full width
                        maxWidth: '100%', // Ensures it does not exceed the viewport width
                        borderRadius: '8px', // Optional: rounded corners
                        margin: '20px 0', // Optional: adds space around the button
                      }}
                    >
                      Join the Waitlist
                    </Button>
                  </Box>
                  <div className='block__list--optin'>
                    <Typography
                      variant='h4'
                      sx={{ margin: '2rem auto', fontSize: '1.75rem' }}
                    >
                      <strong>Secure Your Spot Today</strong>
                    </Typography>
                    <p>
                      Don't miss out on this exclusive opportunity. Sign up now
                      to secure your place among the first 50 broker owners and
                      managers to join REACirc Broker Connect. Take advantage of
                      this limited-time offer to transform your brokerage. With
                      REACirc, you'll build valuable connections, enhance your
                      market presence, and drive growth for your business. Join
                      today and be at the forefront of real estate networking
                      and collaboration!
                    </p>
                  </div>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </section>

      <Modal
        show={modalShow}
        onHide={handleClose}
        size='lg'
        centered
        backdrop='static' // Prevents closing the modal by clicking outside of it
        keyboard={false} // Prevents closing the modal with the ESC key
      >
        <Modal.Header
          closeButton
          style={{
            backgroundColor: '#1976d2', // Blue background
            color: '#FFFFFF', // White text
          }}
        >
          <Modal.Title
            style={{
              backgroundColor: '#1976d2', // Blue background
              color: '#FFFFFF', // White text
              fontWeight: 'bold', // Bold text
              textAlign: 'center', // Center text
              padding: '16px', // Adjust padding if needed
            }}
          >
            {' '}
            Join REACirc Broker Connect
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className='modal-intro'>
            We are excited to announce that REACirc Broker Connect is scheduled
            to be live Fall 2024. Be among the first to experience the next
            level of real estate networking and collaboration. Please fill out
            the form below to secure your spot and be updated with the latest
            information and offers.
          </p>
          <p className='modal-intro'>
            Please fill out the form below to secure your spot and to be kept up
            to date on the launch date.
          </p>

          <Form onSubmit={handleFormSubmit} className='form-box'>
            <FormControl fullWidth required>
              <InputLabel id='role-select-label'>Select Role</InputLabel>
              <MuiSelect
                labelId='role-select-label'
                id='role-select'
                name='brokerRole'
                value={formData.brokerRole}
                onChange={handleChange}
                label='Select Role'
              >
                <MenuItem value=''>
                  <em>None</em>
                </MenuItem>
                <MenuItem value='Broker Owner'>Broker Owner</MenuItem>
                <MenuItem value='Broker Manager'>Broker Manager</MenuItem>
                <MenuItem value='Broker Owner/Manager'>
                  Broker Owner/Manager
                </MenuItem>
              </MuiSelect>
            </FormControl>
            <Form.Group controlId='formFirstName'>
              <Form.Control
                type='text'
                placeholder='First Name'
                name='firstName'
                value={formData.firstName}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId='formLastName'>
              <Form.Control
                type='text'
                placeholder='Last Name'
                name='lastName'
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId='formProvince'>
              <div className='form-select'>
                <Select
                  name='province'
                  options={provinceOptions}
                  value={provinceOptions.find(
                    (option) => option.value === formData.province
                  )}
                  onChange={handleSelectChange}
                  placeholder='Select Province'
                  isClearable
                />
              </div>
            </Form.Group>
            <Form.Group controlId='formCity'>
              <InputLabel style={{ marginLeft: '10px' }} htmlFor='formCity'>
                City
              </InputLabel>
              <div className='form-select'>
                <Creatable
                  name='city'
                  options={cityOptions}
                  value={
                    sortedCityOptions.find(
                      (option) => option.value === formData.city
                    ) || { label: formData.city, value: formData.city }
                  }
                  onChange={handleCityChange}
                  placeholder='Select or enter city'
                />
                <span style={{ marginLeft: '5px', fontSize: '10px' }}>
                  Type in your city if not found in the list
                </span>
              </div>
            </Form.Group>
            <Form.Group controlId='formBrokerage'>
              <Form.Control
                type='text'
                placeholder='Brokerage Name'
                name='brokerage'
                value={formData.brokerage}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId='formEmail'>
              <Form.Control
                type='email'
                placeholder='Email Address'
                name='email'
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId='formMobileNumber'>
              <Form.Control
                type='tel'
                placeholder='Mobile Number'
                name='mobileNumber'
                value={formData.mobileNumber}
                onChange={handleChange}
                isInvalid={!!errors.mobileNumber}
                required
              />
              <Form.Control.Feedback
                style={{ marginLeft: '10px' }}
                type='invalid'
              >
                {errors.mobileNumber}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId='formPassword'>
              <Form.Control
                type='password'
                placeholder='Create a Password'
                name='password'
                value={formData.password}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId='formBasicPasswordVerify'>
              <Form.Control
                type='password'
                name='passwordVerify'
                value={formData.passwordVerify}
                onChange={handleChange}
                placeholder='Verify your password'
                isInvalid={!!errors.password}
              />
              <Form.Control.Feedback
                style={{ marginLeft: '10px' }}
                type='invalid'
              >
                {errors.password}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className='file-upload' controlId='formPhoto'>
              <Form.Control
                type='file'
                id='fileInput'
                name='photo'
                onChange={handleChange}
                accept='image/*'
                style={{ display: 'none' }} // Hide the default file input
              />
              {formError && <p className='text-danger'>{formError}</p>}
              {photoPreview && (
                <div className='photo-preview'>
                  <img
                    src={photoPreview}
                    alt='Selected Preview'
                    style={{
                      maxWidth: '100%',
                      border: '1px solid #ccc',
                      padding: '5px',
                      borderRadius: '5px',
                      marginTop: '5px',
                      marginBottom: '5px',
                    }}
                  />
                </div>
              )}
              <div className='button-group'>
                <label htmlFor='fileInput' className='file-label'>
                  {formData.photo ? 'Change Headshoot' : 'Choose Headshot'}
                </label>
                {photoPreview && (
                  <label
                    variant='secondary'
                    onClick={() => {
                      setFormData({ ...formData, photo: null });
                      setPhotoPreview('');
                    }}
                    style={{
                      marginLeft: '10px',
                      color: 'black',
                      backgroundColor: 'lightgrey',
                    }}
                  >
                    Clear Headshot
                  </label>
                )}
              </div>
            </Form.Group>

            <Button className='btn btn--optin' type='submit'>
              Join the the Wait List
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      {/* Success Modal */}
      <Modal
        show={successModalShow}
        onHide={handleSuccessClose}
        size='lg'
        aria-labelledby='success-modal-title'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='success-modal-title'>Success!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Typography variant='h6' className='text-center'>
            Congratulations!
            <br />
          </Typography>
          <center>
            You are on the wait list.
            <br />
            You have been added to the wait list.
            <br />
            We will keep you updated on the launch date.
          </center>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleSuccessClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Broker;

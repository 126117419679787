// src/services/userService.js

const API_URL = process.env.REACT_APP_BACKEND_URL;

export const submitUserRequest = async (userData) => {
  const formData = new FormData();

  Object.keys(userData).forEach((key) => {
    formData.append(key, userData[key]);
  });

  try {
    const response = await fetch(`${API_URL}/externals/create-member`, {
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error submitting user request:", error);
    throw error;
  }
};

export const checkWaitList = async () => {
  try {
    const response = await fetch(`${API_URL}/externals/check-waitlist`, {
      method: "GET",
    });
    if (!response.ok) {
      throw new Error("Failed to fetch waitlist count");
    }
    const waitListCount = await response.json();
    return waitListCount; // Assuming the API returns an object with a 'count' property
  } catch (error) {
    console.error("Error fetching waitlist count:", error);
    return 0; // Default to 0 in case of an error
  }
};

import React, { useState, useEffect } from "react";

const TextToTableConverter = () => {
  const [inputText, setInputText] = useState("");
  const [tableData, setTableData] = useState([]);

  // Load records from localStorage on component mount
  useEffect(() => {
    const storedRecords =
      JSON.parse(localStorage.getItem("tableRecords")) || [];
    setTableData(storedRecords);
  }, []);

  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

  const convertTextToTable = () => {
    const extractedData = [];
    const infoContent = inputText.match(
      /Real Estate Professional Information\s*={3,}([\s\S]*?)(?=Office Hours|Careers|$)/
    );

    // Extract the brokerage name
    const brokerageMatch = inputText.match(/3--\s*(.*)/);
    const brokerageName = brokerageMatch ? brokerageMatch[1].trim() : "N/A";

    if (infoContent && infoContent[1]) {
      const lines = infoContent[1].trim().split("\n");

      const keys = [
        "Licence Effective Date",
        "Licence Number",
        "Licence Status",
        "Licence Expiry",
        "Legal Name",
        "Business Address",
        "Business Phone",
        "Business Fax",
        "Licensed As",
        "Licensed For",
        "Licence Conditions",
        "Discipline",
        "Teams",
        "Managing Brokers",
        "Associate Brokers",
        "Representatives",
      ];

      let currentKey = "";
      const tempStorage = {};

      keys.forEach((key) => {
        tempStorage[key] = "N/A"; // Initialize all keys with "N/A"
      });

      lines.forEach((line) => {
        const trimmedLine = line.trim();

        // Ignore dashed lines
        if (trimmedLine === "" || trimmedLine.includes("-----")) return;

        keys.forEach((key) => {
          if (trimmedLine.startsWith(key)) {
            currentKey = key;
            const value = extractValue(trimmedLine);
            tempStorage[currentKey] = value; // Set value directly for matching keys
          }
        });

        if (
          currentKey &&
          trimmedLine.length > 0 &&
          !keys.some((key) => trimmedLine.startsWith(key))
        ) {
          const cleanedValue = extractValue(trimmedLine);
          tempStorage[currentKey] += `, ${cleanedValue}`; // Append value for continuation
        }
      });

      // Add brokerage name and counts
      extractedData.push({
        ...tempStorage,
        "Brokerage Name": brokerageName,
        "# of Representatives": (tempStorage["Representatives"] || "").split(
          ", "
        ).length,
        "# of Teams": (tempStorage["Teams"] || "").split(", ").length,
      });

      // Check if the Licence Number exists
      const licenseNumber = tempStorage["Licence Number"];
      const existingIndex = tableData.findIndex(
        (record) => record["Licence Number"] === licenseNumber
      );

      let updatedTableData = [...tableData];
      if (existingIndex !== -1) {
        // Update existing record
        updatedTableData[existingIndex] = {
          ...updatedTableData[existingIndex],
          ...extractedData[0],
        };
      } else {
        // Add new record
        updatedTableData.push(extractedData[0]);
      }

      // Update state and localStorage
      setTableData(updatedTableData);
      localStorage.setItem("tableRecords", JSON.stringify(updatedTableData));
    } else {
      alert("No Real Estate Professional Information found.");
    }
  };

  const extractValue = (line) => {
    const cleanValue = line.replace(/.*?:\s*/, "").trim();
    return cleanValue || "N/A";
  };

  const handleCopyToClipboard = () => {
    // Create a string representation of the table
    const headerRow = [
      "Brokerage Name",
      ...Object.keys(tableData[0]).filter(
        (key) =>
          key !== "Brokerage Name" &&
          key !== "Representatives" &&
          key !== "Teams"
      ),
    ];
    const headerString = headerRow.join("\t") + "\n"; // Use tab character for separation

    const rowsString = tableData
      .map(
        (record) =>
          [
            record["Brokerage Name"],
            ...Object.keys(record)
              .filter(
                (key) =>
                  key !== "Brokerage Name" &&
                  key !== "Representatives" &&
                  key !== "Teams"
              )
              .map((key) => record[key]),
          ].join("\t") // Use tab character for separation
      )
      .join("\n"); // Each row on a new line

    const finalTableString = headerString + rowsString; // Combine header and rows

    navigator.clipboard.writeText(finalTableString).then(() => {
      alert("All table records copied to clipboard!");
    });
  };

  const handleClearLocalStorage = () => {
    localStorage.removeItem("tableRecords"); // Clear the local storage
    setTableData([]); // Update state to reflect cleared data
    window.location.reload(); // Refresh the page
  };

  return (
    <div>
      <h2>Text to Table Converter</h2>
      <textarea
        rows="10"
        cols="150"
        value={inputText}
        onChange={handleInputChange}
        placeholder="Paste your text here..."
      />
      <br />
      <button onClick={convertTextToTable}>Convert to Table</button>
      <button onClick={handleCopyToClipboard}>Copy All Records</button>
      <button onClick={handleClearLocalStorage}>Clear All Records</button>

      <h4>Brokerages Loaded: {tableData.length}</h4>
      {tableData.length > 0 && (
        <div>
          <h3>Converted Table (Horizontal Layout)</h3>
          <table border="1">
            <thead>
              <tr>
                <th>Brokerage Name</th>
                {Object.keys(tableData[0])
                  .filter(
                    (key) =>
                      key !== "Brokerage Name" &&
                      key !== "Representatives" &&
                      key !== "Teams"
                  ) // Exclude Brokerage Name from auto-generation
                  .map((key) => (
                    <th key={key}>{key}</th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {tableData.map((record, index) => (
                <tr key={index}>
                  <td>{record["Brokerage Name"]}</td>
                  {Object.keys(record)
                    .filter(
                      (key) =>
                        key !== "Brokerage Name" &&
                        key !== "Representatives" &&
                        key !== "Teams"
                    )
                    .map((key, i) => (
                      <td key={i}>{record[key]}</td>
                    ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default TextToTableConverter;

import React from "react";
import { Container, Typography, Grid, Paper } from "@mui/material";

function AboutUs() {
  return (
    <Container className="contact-container">
      <Paper elevation={3} sx={{ p: 3, mt: 1, mb: 4 }}>
        <Typography
          variant="h3"
          gutterBottom
          align="center"
          sx={{
            mt: 1,
            mb: 2,
            color: "white",
            backgroundColor: "primary.main",
            padding: "20px",
            borderRadius: "4px",
          }}
        >
          About Us
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Paper
              elevation={3}
              sx={{
                p: 3,
                border: "5px solid red",
                height: "100%",
                backgroundColor: "rgba(255, 0, 0, 0.05)",
              }}
            >
              <Typography variant="h5" gutterBottom>
                Our Mission
              </Typography>
              <Typography variant="body1">
                At RealtyOS, our mission is twofold: to be the premier system
                for internal real estate brokerage agent management, and to
                become the leading platform connecting top-producing individual
                agents for collaboration and business improvement. We're
                dedicated to revolutionizing the real estate industry by
                providing innovative technology solutions that not only
                streamline workflows but also significantly enhance
                profitability and foster meaningful professional connections.
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper
              elevation={3}
              sx={{
                p: 3,
                border: "5px solid gold",
                backgroundColor: "rgba(255, 215, 0, 0.05)",
              }}
            >
              <Typography variant="h5" gutterBottom>
                Our Vision
              </Typography>
              <Typography variant="body1">
                We envision a future where real estate professionals are
                seamlessly connected, empowering them to collaborate effectively
                and drive business growth. Our vision is to create a dynamic
                ecosystem where top-producing agents can share insights, best
                practices, and opportunities, while brokerages can efficiently
                manage and support their teams. By leveraging cutting-edge
                technology, we aim to foster a more interconnected, productive,
                and profitable real estate industry that ultimately benefits
                both professionals and their clients.
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper
              elevation={3}
              sx={{
                p: 3,
                border: "5px solid green",
                backgroundColor: "rgba(0, 255, 0, 0.05)",
              }}
            >
              <Typography variant="h5" gutterBottom>
                Our Team
              </Typography>
              <Typography variant="body1">
                Our small but mighty team combines entrepreneurial spirit with
                deep industry expertise. With 14+ years of residential real
                estate experience, a BCom in Entrepreneurial Management, and
                extensive software development skills, we bring together all the
                necessary expertise to create cutting-edge solutions. We're
                leveraging the latest technology to develop systems that
                significantly improve operations for both brokerages and
                individual agents. Our passion lies in solving complex industry
                challenges and delivering exceptional value to our users.
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper
              elevation={3}
              sx={{
                p: 3,
                border: "5px solid #007bff",
                backgroundColor: "rgba(0, 123, 255, 0.05)",
              }}
            >
              <Typography variant="h5" gutterBottom>
                Our Values
              </Typography>
              <Typography variant="body1">
                <ul>
                  <li>
                    Innovation: We constantly push the boundaries of what's
                    possible in real estate technology.
                  </li>
                  <li>
                    Integrity: We operate with transparency and honesty in all
                    our dealings.
                  </li>
                  <li>
                    Customer-Centric: Our users' success is our top priority.
                  </li>
                  <li>
                    Collaboration: We believe in the power of teamwork and
                    partnerships.
                  </li>
                </ul>
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}

export default AboutUs;

import React from "react";
import { Container, Typography, Box } from "@mui/material";

const CookiePolicy = () => {
  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h3" component="h1" gutterBottom>
          Cookie Policy
        </Typography>

        <Typography variant="h5" gutterBottom>
          Last updated: September 18, 2024
        </Typography>

        <Typography variant="body1" paragraph>
          At RealtyOS Inc., we are committed to being transparent about how we
          and our partners collect and use data. This Cookie Policy explains how
          REACirc uses cookies and similar technologies to recognize you when
          you visit our website. It explains what these technologies are and why
          we use them, as well as your rights to control our use of them.
        </Typography>

        <Typography variant="body1" paragraph>
          Please read this Cookie Policy together with our Privacy Policy, which
          provides additional information about our use of personal information
          and any rights you may have in relation to your personal information.
          Terms used but not defined in this Cookie Policy can be found in our
          Privacy Policy or Terms of Service.
        </Typography>

        <Typography variant="h6" gutterBottom>
          What are cookies?
        </Typography>
        <Typography variant="body1" paragraph>
          Cookies are small data files stored on your hard drive or in device
          memory that help us improve our Services and your experience, see
          which areas and features of our Services are popular, and count
          visits. Web beacons (also known as "pixel tags" or "clear GIFs") are
          electronic images that may be used on our Services and help deliver
          cookies, count visits, and understand usage and campaign
          effectiveness.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Does REACirc use cookies, and why?
        </Typography>
        <Typography variant="body1" paragraph>
          Yes, REACirc uses cookies. Some cookies are associated with your
          Account and personal information to remember whether you are logged in
          and your user preferences. Other cookies are not tied to your Account
          but help us with activities like analytics and customization. Cookies
          can help us remember your preferences to give you a personalized
          experience and make your interactions faster.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Types of Cookies We Use
        </Typography>
        <Typography variant="body1" paragraph>
          Each cookie on our Services falls into one of three different
          categories:
        </Typography>

        <Typography variant="body1" component="ol">
          <li>
            <strong>Strictly Necessary Cookies:</strong> These cookies are
            essential for the core functionality of our Services. You may
            disable these by changing your browser settings, but this may affect
            how the Services function.
          </li>
          <li>
            <strong>Analytics and Functional Cookies:</strong> These cookies
            help us understand how our users interact with our Services,
            allowing us to improve your experience and our Services.
          </li>
          <li>
            <strong>Advertising Cookies:</strong> These cookies are used to make
            advertising messages more relevant to you and your interests.
          </li>
        </Typography>

        <Typography variant="h6" gutterBottom>
          Your Choices Regarding Cookies
        </Typography>
        <Typography variant="body1" paragraph>
          You don't have to accept our cookies to use our Services. Although
          most web browsers are set to accept cookies by default, you can change
          your browser settings to manage cookies on a site-by-site basis.
          However, please note that deleting or rejecting cookies may limit your
          ability to use certain portions of our Services and you may have a
          degraded experience.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Changes to this Cookie Policy
        </Typography>
        <Typography variant="body1" paragraph>
          We may update this Cookie Policy from time to time. When we make
          changes to this Cookie Policy, we will change the "Last updated" date
          at the beginning of this policy. If we make material changes to this
          policy, we will notify you through appropriate communication channels.
          All changes shall be effective from the date of publication unless
          otherwise provided in the notification.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions about our use of cookies or other
          technologies, please email us at{" "}
          <a href="mailto:info@reacirc.com">info@reacirc.com</a> or contact us
          at:
        </Typography>

        <Typography variant="body1" paragraph>
          RealtyOS Inc.
          <br />
          3358 Opal Road
          <br />
          Nanaimo, BC V9T 2V5
        </Typography>
      </Box>
    </Container>
  );
};

export default CookiePolicy;

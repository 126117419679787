import React from "react";
import { Container, Typography, Box } from "@mui/material";

const Privacy = () => {
  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h3" component="h1" gutterBottom>
          Privacy Policy
        </Typography>

        <Typography variant="h5" gutterBottom>
          Last updated: September 18, 2024
        </Typography>

        <Typography variant="body1" paragraph>
          This Privacy Policy describes how RealtyOS Inc. ("RealtyOS", "we",
          "us", or "our") collects, uses, and discloses your information when
          you use our REACirc service (the "Service"). We are a Canadian
          company, Federally Incorporated.
        </Typography>

        <Typography variant="h6" gutterBottom>
          1. Information We Collect
        </Typography>
        <Typography variant="body1" paragraph>
          We collect information that you provide directly to us, such as when
          you create an account, update your profile, use interactive features,
          request customer support, or otherwise communicate with us. This may
          include your name, email address, password, profile information, and
          any other information you choose to provide.
        </Typography>

        <Typography variant="h6" gutterBottom>
          2. How We Use Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We use the information we collect to provide, maintain, and improve
          the Service, process transactions, send you technical notices and
          support messages, and respond to your comments and questions. We may
          also use your information to send you news and information about our
          products and services, and communicate with you about products,
          services, offers, and events offered by us and others.
        </Typography>

        <Typography variant="h6" gutterBottom>
          3. How We Share Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We may share your information with third-party vendors, consultants,
          and other service providers who need access to such information to
          carry out work on our behalf. We may also share your information when
          we believe it is necessary to comply with a legal requirement or to
          protect our rights, protect your safety or the safety of others,
          investigate fraud, or respond to a government request.
        </Typography>

        <Typography variant="h6" gutterBottom>
          4. Your Choices
        </Typography>
        <Typography variant="body1" paragraph>
          You can access and update certain information about you from within
          the Service. You can also opt-out of receiving promotional
          communications from us by following the instructions in those
          messages. Even if you opt-out, we may still send you non-promotional
          communications, such as those about your account or our ongoing
          business relations.
        </Typography>

        <Typography variant="h6" gutterBottom>
          5. Data Retention
        </Typography>
        <Typography variant="body1" paragraph>
          We will retain your information for as long as your account is active
          or as needed to provide you the Service. We will retain and use your
          information as necessary to comply with our legal obligations, resolve
          disputes, and enforce our agreements.
        </Typography>

        <Typography variant="h6" gutterBottom>
          6. Changes to this Policy
        </Typography>
        <Typography variant="body1" paragraph>
          We may update this Privacy Policy from time to time. We will notify
          you of any changes by posting the new Privacy Policy on this page and
          updating the "Last updated" date at the top of this policy.
        </Typography>

        <Typography variant="h6" gutterBottom>
          7. Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions about this Privacy Policy, please contact us
          at <a href="mailto:privacy@reacirc.com">privacy@reacirc.com</a>.
        </Typography>
      </Box>
    </Container>
  );
};

export default Privacy;

import React, { useState, useEffect } from "react";
import { Container, Button, Form, Modal } from "react-bootstrap";
import { Button as MuiButton, CircularProgress } from "@mui/material";
import { AssignmentTurnedIn } from "@mui/icons-material";
import nsc2024Logo from "../assets/img/nsc2024-logo.png"; // Adjust the path according to your project structure
import { submitUserRequest } from "../services/userService"; // Import the service
import Select from "react-select"; // Import react-select
import Creatable from "react-select/creatable"; // Import Creatable from react-select
import { provinces, citiesByProvince } from "../data/canadianCities"; // Import data

const NSC2024 = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    city: "",
    province: "",
    brokerage: "",
    email: "",
    phoneNumber: "",
    photo: null,
    type: "Conference",
    password: "", // Add password here
    passwordVerify: "",
  });
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    city: "",
    province: "",
    brokerage: "",
    email: "",
    phoneNumber: "",
    photo: "",
    type: "",
    password: "", // Add password here
    passwordVerify: "",
  });

  const [modalShow, setModalShow] = useState(false); // State to manage modal visibility
  const [photoPreview, setPhotoPreview] = useState(""); // State for image preview
  const [cityOptions, setCityOptions] = useState([]);
  const [provinceOptions] = useState(provinces);

  // State for result modals
  const [resultModalShow, setResultModalShow] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [resultType, setResultType] = useState("success"); // Can be "success" or "error"
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Update city options based on selected province
  useEffect(() => {
    if (formData.province) {
      const cities = citiesByProvince[formData.province] || [];
      // Remove duplicates

      const uniqueCities = Array.from(
        new Map(cities.map((city) => [city.value, city])).values()
      );

      const sortedCities = uniqueCities.sort((a, b) =>
        a.label.localeCompare(b.label)
      );
      setCityOptions(sortedCities);
    } else {
      setCityOptions([]);
    }
  }, [formData.province]);

  useEffect(() => {
    const { password, passwordVerify } = formData;
    if (password && passwordVerify) {
      if (password.length < 8) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          password: "Password must be at least 8 characters long.",
        }));
      } else if (password !== passwordVerify) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          password: "Passwords don't match.",
        }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, password: "" }));
      }
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, password: "" }));
    }
  }, [formData.password, formData.passwordVerify]);

  // Phone number formatting function
  const formatPhoneNumber = (value) => {
    const cleaned = ("" + value).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return value;
  };

  const validatePhoneNumber = (value) => {
    // Regex for North American phone numbers
    const phoneRegex =
      /^(?:\+1\s?)?\(?([2-9][0-9]{2})\)?[\s.-]?([2-9][0-9]{2})[\s.-]?([0-9]{4})$/;

    if (!value) {
      return "Phone number is required.";
    }

    if (!phoneRegex.test(value)) {
      return "Phone number is invalid. Please enter a valid North American phone number.";
    }

    return "";
  };

  const validatePasswords = () => {
    const { password, passwordVerify } = formData;

    let isValid = true;
    let passwordError = "";

    if (password.length < 8) {
      passwordError = "Password must be at least 8 characters long.";
      isValid = false;
    } else if (password !== passwordVerify) {
      passwordError = "Passwords don't match.";
      isValid = false;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      password: passwordError,
    }));

    return isValid;
  };

  // Handle input changes
  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === "file") {
      const file = files[0];
      if (file) {
        if (file.size > 10485760) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            photo:
              "File size exceeds 10MB limit. Please choose a smaller file.",
          }));
          setFormData((prevFormData) => ({ ...prevFormData, photo: null }));
          setPhotoPreview("");
        } else {
          setErrors((prevErrors) => ({ ...prevErrors, photo: "" }));
          setFormData((prevFormData) => ({ ...prevFormData, photo: file }));
          const reader = new FileReader();
          reader.onloadend = () => {
            setPhotoPreview(reader.result);
          };
          reader.readAsDataURL(file);
        }
      }
    } else {
      const newValue =
        name === "phoneNumber" ? formatPhoneNumber(value) : value;

      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: newValue,
      }));

      if (name === "phoneNumber") {
        const error = validatePhoneNumber(value);
        setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
      } else if (name === "password" || name === "passwordVerify") {
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
      }
    }
  };

  // Handle select changes
  const handleSelectChange = (selectedOption, actionMeta) => {
    if (actionMeta.name === "province") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        province: selectedOption ? selectedOption.value : "",
        city: "",
      }));
      setErrors((prevErrors) => ({ ...prevErrors, province: "" }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [actionMeta.name]: selectedOption ? selectedOption.value : "",
      }));
      setErrors((prevErrors) => ({ ...prevErrors, [actionMeta.name]: "" }));
    }
  };

  // Handle city input changes
  const handleCityChange = (newValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      city: newValue ? newValue.value : "",
    }));
    setErrors((prevErrors) => ({ ...prevErrors, city: "" }));
  };

  // Function to format phone number
  const cleanPhoneNumber = (phoneNumber) => {
    return phoneNumber.replace(/\D/g, ""); // Remove all non-numeric characters
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!validatePasswords()) {
      return;
    }

    if (!formData.photo) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        photo: "Please upload a headshot.",
      }));
      return;
    }

    const phoneError = validatePhoneNumber(formData.phoneNumber);
    if (phoneError) {
      setErrors((prevErrors) => ({ ...prevErrors, phoneNumber: phoneError }));
      return;
    }

    const hasErrors = Object.values(errors).some((error) => error !== "");
    if (hasErrors) {
      return;
    }

    let isMounted = true; // Flag to track component mount status

    setIsSubmitting(true);

    try {
      const cleanedPhoneNumber = cleanPhoneNumber(formData.phoneNumber);
      const data = {
        ...formData,
        phoneNumber: cleanedPhoneNumber,
      };
      await submitUserRequest(data);

      if (isMounted) {
        setResultMessage(
          "Registration is successful! Please check your email for further instructions."
        );
        setResultType("success");
        setModalShow(false);
        setResultModalShow(true);
      }
    } catch (error) {
      if (isMounted) {
        setResultMessage(
          "An error occurred while submitting the form. Please try again."
        );
        setResultType("error");
        setModalShow(false);
        setResultModalShow(true);
      }
    } finally {
      setIsSubmitting(false);
    }

    return () => {
      isMounted = false;
    }; // Cleanup function to update flag on unmount
  };

  const handleShow = () => setModalShow(true);
  const handleClose = () => setModalShow(false);
  const handleResultModalClose = () => setResultModalShow(false);

  // Sort cityOptions alphabetically by the label
  const sortedCityOptions = [...cityOptions].sort((a, b) =>
    a.label.localeCompare(b.label)
  );
  const isFormValid =
    Object.values(errors).every((error) => error === "") &&
    Object.values(formData).every((value) => value !== "" && value !== null);

  return (
    <>
      <section id="hero" className="section nsc2024_bg__hero">
        <Container>
          <div className="row justify-content-center text-center">
            <div className="col-md-10">
              <img
                src={nsc2024Logo}
                alt="Royal LePage National Sales Conference 2024 Logo"
              />
              <h1>
                Connect and Elevate at the <br />
                <span className="highlight--yellow">
                  Royal LePage National Sales Conference!
                </span>
                <i
                  className="fas fa-volume-up ml-2"
                  style={{
                    cursor: "pointer",
                    fontSize: "16px",
                    position: "relative",
                    top: "-40px",
                  }}
                ></i>
              </h1>
              <h2>
                <strong>REACirc for the National Sales Conference </strong>
                (UnOfficial)
                <br />
                blends the power of our agent network with the unique
                opportunities of the conference. Enhance your connections and
                share insights with other attendees.
              </h2>
              <h2>
                Designed by a top-performing real estate agent with over a
                decade of industry experience, REACirc offers unparalleled
                networking and collaboration, tailored specifically for the Real
                Estate community.
              </h2>

              <MuiButton
                variant="contained"
                color="secondary"
                onClick={handleShow}
                startIcon={<AssignmentTurnedIn />}
                // style={{ fontWeight: "bold", padding: "1rem 2rem" }}
              >
                Sign Up For Free Access for NSC 2024
              </MuiButton>
            </div>
          </div>
        </Container>
      </section>

      <section id="sectionOffset">
        <Container>
          <div className="row justify-content-center">
            <div className="col-lg-10 bordered-list">
              <div className="block__list">
                <div className="text-center mb-4">
                  <h3 className="text-center mb-4">
                    Experience the Best of Networking and Collaboration with
                    REACirc
                  </h3>
                  <ul className="icon__check--blue mb-5">
                    <li>
                      <strong>
                        Connect and Collaborate: Unique Networking
                        Opportunities:
                      </strong>
                      <br />
                      Dive into a digital network of Royal LePage National Sales
                      Conference 2024 attendees from across the country.
                      Exchange ideas, discuss strategies, and build meaningful
                      connections that last. This is your chance to collaborate
                      on ideas and find new ways to grow your business together.
                    </li>

                    <br />
                    <li>
                      <strong>Share Conference Insights:</strong>
                      <br />
                      REACirc is where attendees can share the key takeaways
                      from the sessions they’ve attended. You can post your
                      insights in REACirc's dedicated NSC forums and have
                      conversations with other professionals, creating a space
                      for everyone to exchange knowledge and best practices.
                    </li>

                    <br />
                    <li>
                      <strong>
                        Welcome Newcomers: Your First-Time Attendee Hub:
                      </strong>
                      <br />
                      As a first-time attendee at the Royal LePage National
                      Sales Conference 2024, you’ll find a special space just
                      for you. Connect with fellow newcomers, get tips on making
                      the most of your experience, and start building valuable
                      relationships from day one. Dive in, share your
                      experiences, and get the support you need to navigate the
                      conference with ease.
                    </li>
                    <br />

                    <li>
                      <strong>Estabilsh Referrals Partnerships:</strong>
                      <br />
                      REACirc offers a great space for attendees to connect and
                      build referral relationships. You can network with other
                      professionals, find potential partners, and create
                      alliances that help grow your business. It's all about
                      making those valuable connections and working together for
                      mutual success.
                    </li>
                    <br />
                    <li>
                      <strong>Share Local Locations and Activities:</strong>
                      <br />
                      Discover and share recommendations for great local spots
                      and activities. Whether it’s a must-visit restaurant or a
                      fun event happening nearby, let others know where to find
                      the best experiences during the conference.
                    </li>
                  </ul>
                  <Button className="btn btn--optin" onClick={handleShow}>
                    Sign Up For Free Access for NSC 2024
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>

      <Modal
        show={modalShow}
        onHide={handleClose}
        size="lg"
        centered
        backdrop="static" // Prevents closing the modal by clicking outside of it
        keyboard={false} // Prevents closing the modal with the ESC key
      >
        <Modal.Header
          closeButton
          style={{
            backgroundColor: "#1976d2", // Blue background
            color: "#FFFFFF", // White text
          }}
        >
          <Modal.Title
            style={{
              backgroundColor: "#1976d2", // Blue background
              color: "#FFFFFF", // White text
              fontWeight: "bold", // Bold text
              textAlign: "center", // Center text
              padding: "16px", // Adjust padding if needed
            }}
          >
            Sign Up For Free Access
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="modal-intro">
            Join us for the Royal LePage National Sales Conference 2024 and
            experience the power of REACirc, the ultimate networking and
            collaboration tool.{" "}
          </p>
          <p className="modal-intro">
            Please fill out the form below to secure your free access and stay
            updated with exclusive conference insights and opportunities.
          </p>

          <p>Valid registration for NSC 2024 required.</p>
          <Form onSubmit={handleFormSubmit} className="form-box">
            <Form.Group controlId="formFirstName">
              <Form.Control
                type="text"
                placeholder="First Name"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
                id="formFirstName"
              />
            </Form.Group>
            <Form.Group controlId="formLastName">
              <Form.Control
                type="text"
                placeholder="Last Name"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
                id="formLastName"
              />
            </Form.Group>

            <Form.Group controlId="formProvince">
              <div className="form-select">
                <Select
                  name="province"
                  options={provinceOptions}
                  value={provinceOptions.find(
                    (option) => option.value === formData.province
                  )}
                  onChange={handleSelectChange}
                  placeholder="Select Province"
                  isClearable
                  id="formProvince"
                />
              </div>
            </Form.Group>
            <Form.Group controlId="formCity">
              <div className="form-select">
                <Creatable
                  name="city"
                  options={cityOptions}
                  value={
                    sortedCityOptions.find(
                      (option) => option.value === formData.city
                    ) || { label: formData.city, value: formData.city }
                  }
                  onChange={handleCityChange}
                  placeholder="Select or enter city"
                  id="formCity"
                />
              </div>
            </Form.Group>
            <Form.Group controlId="formBrokerage">
              <Form.Control
                type="text"
                placeholder="Brokerage Name"
                name="brokerage"
                value={formData.brokerage}
                onChange={handleChange}
                required
                id="formBrokerage"
              />
            </Form.Group>
            <Form.Group controlId="formNSCEmail">
              <Form.Control
                type="email"
                placeholder="Email Address"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                id="formNSCEmail"
              />
            </Form.Group>
            <Form.Group controlId="formphoneNumber">
              <Form.Control
                type="text"
                placeholder="Mobile Number"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                required
                id="formphoneNumber"
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber}
              />
              {errors.phoneNumber && (
                <Form.Text
                  style={{ marginLeft: "10px" }}
                  className="text-danger"
                >
                  {errors.phoneNumber}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group controlId="password">
              <Form.Control
                type="password"
                placeholder="Create a Password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
                id="password"
              />
            </Form.Group>
            <Form.Group controlId="passwordVerify">
              <Form.Label>Verify Password</Form.Label>
              <Form.Control
                type="password"
                id="passwordVerify"
                name="passwordVerify"
                value={formData.passwordVerify}
                onChange={handleChange}
                placeholder="Verify your password"
                isInvalid={!!errors.password}
              />
              <Form.Control.Feedback
                style={{ marginLeft: "10px" }}
                type="invalid"
              >
                {errors.password}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="file-upload" controlId="formPhoto">
              <Form.Control
                type="file"
                id="fileInput"
                name="photo"
                onChange={handleChange}
                accept="image/*"
                style={{ display: "none" }} // Hide the default file input class
              />
              {photoPreview && (
                <div className="photo-preview">
                  <img
                    src={photoPreview}
                    alt="Selected Preview"
                    style={{
                      maxWidth: "100%",
                      border: "1px solid #ccc",
                      padding: "5px",
                      borderRadius: "5px",
                      marginTop: "5px",
                      marginBottom: "5px",
                    }}
                  />
                </div>
              )}
              <div className="button-group">
                <label htmlFor="fileInput" className="file-label">
                  {formData.photo ? "Change Headshoot" : "Choose Headshot"}
                </label>
                {photoPreview && (
                  <label
                    onClick={() => {
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        photo: null,
                      }));
                      setPhotoPreview("");
                    }}
                    style={{
                      marginLeft: "10px",
                      color: "black",
                      backgroundColor: "lightgrey",
                      cursor: "pointer",
                    }}
                  >
                    Clear Headshot
                  </label>
                )}
              </div>
              {errors.photo && <p className="text-danger">{errors.photo}</p>}
            </Form.Group>

            <MuiButton
              type="submit"
              variant="contained"
              color="secondary"
              startIcon={
                isSubmitting ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <AssignmentTurnedIn />
                )
              }
              disabled={!isFormValid || isSubmitting}
            >
              {isSubmitting ? "Submitting..." : "Sign Up For Free Access"}
            </MuiButton>
          </Form>
        </Modal.Body>
      </Modal>
      {/* Result Modal */}
      <Modal
        show={resultModalShow}
        onHide={handleResultModalClose}
        size="lg"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {resultType === "success" ? "Success" : "Error"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{resultMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleResultModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NSC2024;

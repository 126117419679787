import React, { useState, useEffect } from "react";
import NavBar from "./components/NavBar";
import Home from "./components/Home";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import TextToTableConverter from "./components/TextToTableConverter.js"; // Import the new component

function App() {
  const [selectedSection, setSelectedSection] = useState(
    window.location.search.split("=")[1] || "home"
  );
  useEffect(() => {
    // Update the URL when selectedSection changes
    window.history.pushState({}, "", `?section=${selectedSection}`);
  }, [selectedSection]);

  const handleSectionChange = (section) => {
    setSelectedSection(section);
  };

  return (
    <div className="App">
      <NavBar handleSectionChange={handleSectionChange} />
      {selectedSection !== "url" && (
        <Home incomingSelectedSection={selectedSection} />
      )}{" "}
      {selectedSection === "url" && <TextToTableConverter />}{" "}
      {/* Add UrlViewer */}
      <Footer handleSectionChange={handleSectionChange} />
    </div>
  );
}

export default App;

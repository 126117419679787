import React from "react";
import { Container, Typography, Box } from "@mui/material";

const Terms = () => {
  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h3" component="h1" gutterBottom>
          Platform Terms of Service
        </Typography>

        <Typography variant="h5" gutterBottom>
          Last updated: September 18, 2024
        </Typography>

        <Typography variant="body1" paragraph>
          These Terms of Service (these "Terms") are a binding contract between
          you and RealtyOS Inc. ("we", "us", "our", or "RealtyOS"). The Terms
          apply to your access to, and your use of our website at
          https://reacirc.com (our "Website"), our mobile applications (our
          "App") and our other online products or services that link to these
          Terms (collectively, the "Services") through our Website or App.
        </Typography>

        <Typography variant="h6" gutterBottom>
          PLEASE READ THESE TERMS CAREFULLY.
        </Typography>

        <Typography variant="body1" paragraph>
          BY ACCEPTING THESE TERMS, EITHER BY CLICKING "I ACCEPT," OR BY
          OTHERWISE ACCESSING OR USING OUR SERVICES, YOU ACKNOWLEDGE AND AGREE
          THAT YOU HAVE READ, UNDERSTOOD, AND AGREE OR CONSENT TO BE BOUND BY
          THESE TERMS EITHER (A) ON BEHALF OF YOURSELF AS AN INDIVIDUAL, OR (B)
          IF YOU ARE ACCESSING THE SERVICES ON BEHALF OF AN ENTITY,
          ORGANIZATION, OR COMPANY, ON BEHALF OF SUCH ENTITY, ORGANIZATION OR
          COMPANY FOR WHICH YOU ACT, AND YOU REPRESENT THAT YOU HAVE THE
          AUTHORITY TO BIND SUCH ENTITY, ORGANIZATION OR COMPANY TO THESE TERMS.
        </Typography>

        <Typography variant="h6" gutterBottom>
          ARBITRATION NOTICE
        </Typography>

        <Typography variant="body1" paragraph>
          YOU AGREE THAT DISPUTES ARISING UNDER THESE TERMS WILL BE RESOLVED BY
          BINDING, INDIVIDUAL ARBITRATION AS DESCRIBED IN SECTION 12 (DISPUTE
          RESOLUTION; BINDING ARBITRATION) AND UNLESS YOU OPT OUT PURSUANT TO
          THE INSTRUCTIONS PROVIDED THEREIN, THE EXCLUSIVE USE OF FINAL AND
          BINDING ARBITRATION ON AN INDIVIDUAL BASIS ONLY TO RESOLVE DISPUTES,
          RATHER THAN JURY TRIALS OR CLASS, COLLECTIVE, PRIVATE ATTORNEY GENERAL
          OR REPRESENTATIVE ACTIONS OR PROCEEDINGS.
        </Typography>

        <Typography variant="h6" gutterBottom>
          1. Licenses
        </Typography>
        <Typography variant="body1" paragraph>
          Subject to these Terms, RealtyOS grants you a limited, non-exclusive,
          non-transferable, and revocable license to use the Services for your
          personal or internal business purposes. You may not use the Services
          for any other purpose without our express prior written consent.
        </Typography>

        <Typography variant="h6" gutterBottom>
          2. User Conduct
        </Typography>
        <Typography variant="body1" paragraph>
          You agree not to engage in any of the following prohibited activities:
          (i) copying, distributing, or disclosing any part of the Services in
          any medium, including without limitation by any automated or
          non-automated "scraping"; (ii) using any automated system, including
          without limitation "robots," "spiders," "offline readers," etc., to
          access the Services; (iii) transmitting spam, chain letters, or other
          unsolicited email; (iv) attempting to interfere with, compromise the
          system integrity or security or decipher any transmissions to or from
          the servers running the Services; (v) taking any action that imposes,
          or may impose at our sole discretion an unreasonable or
          disproportionately large load on our infrastructure; (vi) uploading
          invalid data, viruses, worms, or other software agents through the
          Services; (vii) collecting or harvesting any personally identifiable
          information, including account names, from the Services; (viii) using
          the Services for any commercial solicitation purposes; (ix)
          impersonating another person or otherwise misrepresenting your
          affiliation with a person or entity, conducting fraud, hiding or
          attempting to hide your identity; (x) interfering with the proper
          working of the Services; (xi) accessing any content on the Services
          through any technology or means other than those provided or
          authorized by the Services; or (xii) bypassing the measures we may use
          to prevent or restrict access to the Services, including without
          limitation features that prevent or restrict use or copying of any
          content or enforce limitations on use of the Services or the content
          therein.
        </Typography>

        <Typography variant="h6" gutterBottom>
          3. Third-Party Content and Materials
        </Typography>
        <Typography variant="body1" paragraph>
          The Services may contain links to third-party websites and services,
          and/or display content provided by third parties. RealtyOS is not
          responsible for any Third-Party Services. We do not control, endorse,
          or assume responsibility for any Third-Party Services. You use all
          links in Third-Party Services at your own risk.
        </Typography>

        <Typography variant="h6" gutterBottom>
          4. Disclaimers
        </Typography>
        <Typography variant="body1" paragraph>
          THE SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT
          WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT
          LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
          PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT. REALTYOS INC. DOES NOT
          WARRANT THAT THE SERVICES ARE ACCURATE, COMPLETE, RELIABLE, CURRENT,
          OR ERROR-FREE.
        </Typography>

        <Typography variant="h6" gutterBottom>
          5. Limitation of Liability
        </Typography>
        <Typography variant="body1" paragraph>
          TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL REALTYOS INC.,
          ITS AFFILIATES, AGENTS, DIRECTORS, EMPLOYEES, SUPPLIERS OR LICENSORS
          BE LIABLE FOR ANY INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL,
          CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING WITHOUT LIMITATION
          DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE
          LOSSES, ARISING OUT OF OR RELATING TO THE USE OF, OR INABILITY TO USE,
          THE SERVICES.
        </Typography>

        <Typography variant="h6" gutterBottom>
          6. Dispute Resolution and Arbitration
        </Typography>
        <Typography variant="body1" paragraph>
          Any dispute arising from these Terms and your use of the Services
          shall be resolved through binding arbitration, conducted by a single
          arbitrator in accordance with the Commercial Arbitration Rules of the
          Canadian Arbitration Association. The arbitration shall be held in
          Nanaimo, British Columbia, Canada.
        </Typography>

        <Typography variant="h6" gutterBottom>
          7. Governing Law and Venue
        </Typography>
        <Typography variant="body1" paragraph>
          These Terms shall be governed by and construed in accordance with the
          laws of the Province of British Columbia and the federal laws of
          Canada applicable therein, without giving effect to any choice or
          conflict of law provision or rule. Any legal suit, action, or
          proceeding arising out of, or related to, these Terms or the Services
          shall be instituted exclusively in the courts of British Columbia.
        </Typography>

        <Typography variant="h6" gutterBottom>
          8. Termination
        </Typography>
        <Typography variant="body1" paragraph>
          We may terminate or suspend your access to all or any part of the
          Services at any time, with or without cause, with or without notice,
          effective immediately. If you wish to terminate these Terms, you may
          simply discontinue using the Services.
        </Typography>

        <Typography variant="h6" gutterBottom>
          9. Other Legal Provisions
        </Typography>
        <Typography variant="body1" paragraph>
          These Terms constitute the entire agreement between you and RealtyOS Inc
          regarding the use of the Services. Our failure to exercise or enforce
          any right or provision of these Terms shall not operate as a waiver of
          such right or provision. If any provision of these Terms is, for any
          reason, held to be invalid or unenforceable, the other provisions of
          these Terms will be unimpaired and the invalid or unenforceable
          provision will be deemed modified so that it is valid and enforceable
          to the maximum extent permitted by law.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Contact Us
        </Typography>

        <Typography variant="body1" paragraph>
          If you have any questions, comments or concerns about the Terms, you
          may contact us at:
        </Typography>

        <Typography variant="body1" paragraph>
          RealtyOS Inc.
          <br />
          3358 Opal Road
          <br />
          Nanaimo, BC V9T 2V5
          <br />
          <a href="mailto:info@reacirc.com">info@reacirc.com</a>
        </Typography>
      </Box>
    </Container>
  );
};

export default Terms;

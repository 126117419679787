import React from "react";
import {
  Box,
  Modal,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Grid,
  Container,
  Link,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Privacy from "./Legal/Privacy";
import Terms from "./Legal/Terms";
import CookiePolicy from "./Legal/Cookie";
import DPA from "./Legal/DPA";

function Footer({ handleSectionChange }) {
  const [open, setOpen] = React.useState(null);

  const legalItems = [
    { id: "privacy", title: "Privacy Policy", component: Privacy },
    { id: "terms", title: "Terms of Service", component: Terms },
    { id: "cookie", title: "Cookie Policy", component: CookiePolicy },
    { id: "dpa", title: "Data Processing Agreement", component: DPA },
  ];

  const companyItems = [
    { id: "products", title: "Products", section: "products" },
    // { id: "pricing", title: "Pricing", section: "pricing" },
    { id: "contact", title: "Contact", section: "contact" },
    { id: "about", title: "About Us", section: "about" },
  ];

  return (
    <Box
      component="footer"
      sx={{
        bgcolor: "#f5f5f5",
        py: 6,
        mt: "auto",
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              Access
            </Typography>
            <Link
              href="https://portal.reacirc.com"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                color: "text.secondary",
                textDecoration: "none",
                "&:hover": {
                  color: "primary.main",
                  textDecoration: "underline",
                },
              }}
            >
              Login to REACirc
            </Link>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              Company
            </Typography>
            {companyItems.map(({ id, title, section }) => (
              <Link
                key={id}
                onClick={() => handleSectionChange(section)}
                sx={{
                  display: "block",
                  color: "text.secondary",
                  textDecoration: "none",
                  mb: 1,
                  "&:hover": {
                    color: "primary.main",
                    textDecoration: "underline",
                  },
                }}
              >
                {title}
              </Link>
            ))}
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              Legal
            </Typography>
            {legalItems.map(({ id, title, component: Component }) => (
              <React.Fragment key={id}>
                <Link
                  component="button"
                  onClick={() => setOpen(id)}
                  sx={{
                    display: "block",
                    color: "text.secondary",
                    textDecoration: "none",
                    mb: 1,
                    "&:hover": {
                      color: "primary.main",
                      textDecoration: "underline",
                    },
                  }}
                >
                  {title}
                </Link>
                <Modal
                  open={open === id}
                  onClose={() => setOpen(null)}
                  aria-labelledby={`${id}-modal-title`}
                  aria-describedby={`${id}-modal-description`}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: "100%",
                      maxWidth: "md",
                      bgcolor: "background.paper",
                      boxShadow: 24,
                      p: 4,
                      maxHeight: "90vh",
                      overflow: "auto",
                    }}
                  >
                    <AppBar
                      position="sticky"
                      sx={{ bgcolor: "#1976d2", mb: 2 }}
                    >
                      <Toolbar>
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{
                            flexGrow: 1,
                            color: "white",
                            fontWeight: "bold",
                          }}
                        >
                          {title}
                        </Typography>
                        <IconButton
                          edge="end"
                          color="inherit"
                          onClick={() => setOpen(null)}
                          aria-label="close"
                        >
                          <CloseIcon />
                        </IconButton>
                      </Toolbar>
                    </AppBar>
                    <Component />
                  </Box>
                </Modal>
              </React.Fragment>
            ))}
          </Grid>
        </Grid>
        <Typography
          variant="body2"
          color="text.secondary"
          align="center"
          sx={{ mt: 5 }}
        >
          &copy; {new Date().getFullYear()} RealtyOS Inc. All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
}

export default Footer;

import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  CardActions,
  Box,
  Paper,
  Button,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const reaCircColors = ["#0077BE", "#00A86B", "#FFA500"];

const ColoredCard = styled(Card)(({ theme, colorIndex }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  border: `2px solid ${reaCircColors[colorIndex]}`,
  backgroundColor: `${reaCircColors[colorIndex]}10`, // 10% opacity
  "&:hover": {
    boxShadow: `0 0 10px ${reaCircColors[colorIndex]}`,
  },
}));

const ColoredButton = styled(Button)(({ colorIndex, textColor }) => ({
  color: textColor || reaCircColors[colorIndex],
  borderColor: reaCircColors[colorIndex],
  "&:hover": {
    backgroundColor: `${reaCircColors[colorIndex]}20`,
  },
}));

const pricingOptions = {
  agents: [
    {
      title: "Basic",
      price: "20",
      description: "Essential tools for connecting with other agents",
      features: [
        "Forum Participation",
        "Instant Messaging",
        "Access to Resources",
        "Basic Profile",
      ],
      buttonText: "Start free trial",
      buttonVariant: "outlined",
      buttonColorIndex: 0,
      buttonTextColor: "#000",
      buttonLink: "section=agents&product=basic",
    },
    {
      title: "Pro",
      subheader: "Most popular",
      price: "50",
      description: "Advanced features for networking and growth",
      features: [
        "Everything in Basic",
        "Participation in Masterminds",
        "Join Regionally Restricted Groups",
        "Access to File Share",
        "Video Message External Users (coming soon)",
        "Enhanced Profile with Portfolio",
        "Creating/Hosting Events",
        "Priority Support",
      ],
      buttonText: "Start free trial",
      buttonVariant: "contained",
      buttonColorIndex: 1,
      buttonTextColor: "#FFFFFF",
      buttonLink: "section=agents&product=pro",
    },
    {
      title: "Top Producer",
      price: "100",
      description: "Premium tools for industry leaders",
      features: [
        "Everything in Pro",
        "Create Your Own Mastermind Groups",
        "Priority Access to All Future Features",
        "Exclusive Top Producer Network",
      ],
      buttonText: "Start Free Trial",
      buttonVariant: "outlined",
      buttonColorIndex: 2,
      buttonTextColor: "#000",
      buttonLink: "section=agents&product=topProducer",
    },
  ],
  brokerages: [
    {
      title: "Starter",
      price: "250 + $10/agent",
      description: "Essential tools for small to medium brokerages",
      features: [
        "Base: $250/month + $10/agent",
        "Up to 10 Forums, 5 Groups",
        "15GB File Storage",
        "Integrated Email and Text Messaging",
        "Forced Acknowledged Announcements",
        "Integrated System-wide Search",
        "Broadcast Messaging",
        "One-to-One Instant Messaging",
        "Office-wide Messaging",
        "Access to Resources",
        "Coming Soon Listing Promotion",
        "Scheduling System with 1 Schedule",
        "Weekly Office Tour Generator",
        "Email Support",
        "One-time Setup Fee: $750 + $10/agent",
      ],
      buttonText: "Schedule a Demo",
      buttonVariant: "outlined",
      buttonColorIndex: 0,
      buttonTextColor: "#000",
      buttonLink: "section=brokerages&product=starter",
    },
    {
      title: "Growth",
      subheader: "Most popular",
      price: "500 + $12/agent",
      description: "Comprehensive solution for growing brokerages",
      features: [
        "Base: $500/month + $12/agent",
        "20 Forums, 10 Groups",
        "30GB File Storage",
        "Integrated Email and Text Messaging",
        "Forced Acknowledged Announcements",
        "Integrated System-wide Search",
        "Broadcast Messaging",
        "One-to-One Instant Messaging",
        "Office-wide Messaging",
        "Access to Resources",
        "Coming Soon Listing Promotion",
        "Scheduling System with 1 Schedule",
        "Weekly Office Tour Generator",
        "Video Messaging",
        "Multi-office Support (up to 3 locations)",
        "Training & Onboarding",
        "Email Support",
        "One-time Setup Fee: $1000 + $10/agent",
      ],
      buttonText: "Schedule a Demo",
      buttonVariant: "contained",
      buttonColorIndex: 1,
      buttonTextColor: "#FFFFFF",
      buttonLink: "section=brokerages&product=growth",
      selectedProduct: "brokerage",
    },
    {
      title: "Enterprise",
      price: "1,000 + $15/agent",
      description: "Tailored solutions for large brokerages",
      features: [
        "Base: $1,000/month + $15/agent",
        "Unlimited Forums and Groups",
        "100GB File Storage",
        "Integrated Email and Text Messaging",
        "Forced Acknowledged Announcements",
        "Integrated System-wide Search",
        "Broadcast Messaging",
        "One-to-One Instant Messaging",
        "Office-wide Messaging",
        "Access to Resources",
        "Coming Soon Listing Promotion",
        "Scheduling System with Unlimited Schedules",
        "Weekly Office Tour Generator",
        "Video Messaging",
        "Unlimited Multi-office Support",
        "Training & Onboarding",
        "Customized Branding",
        "Dedicated Account Manager",
        "24/7 Priority Support",
        "One-time Setup Fee: $2500 + $10/agent",
      ],
      buttonText: "Schedule a Demo",
      buttonVariant: "outlined",
      buttonColorIndex: 2,
      buttonTextColor: "#000",
      buttonLink: "section=brokerages&product=enterprise",
      selectedProduct: "brokerage",
    },
  ],
  broker: [
    {
      title: "Broker Owner/Manager Connect",
      price: "Free",
      description: "Exclusive network for Broker Owners and Managers",
      features: [
        "Connect with peers",
        "Share ideas and best practices",
        "Collaborate on industry challenges",
        "Access to discussion forums",
        "Owner Zone",
        "Managers Zone",
        "Participate in virtual meetups",
        "Networking opportunities",
        "Industry insights and trends",
        "Instant Private and Group messaging",
        "Video messaging (coming soon)",
        "Access to Resources",
      ],
      buttonText: "Learn More",
      buttonVariant: "contained",
      buttonColorIndex: 0,
      buttonTextColor: "#FFFFFF",
      buttonLink: "section=broker&product=broker",
      selectedProduct: "broker",
    },
  ],
};

function Pricing() {
  const [selectedOption, setSelectedOption] = useState("agents");
  const [selectedProduct, setSelectedProduct] = useState("");

  useEffect(() => {
    // Check localStorage for saved selections
    const savedSection = localStorage.getItem("selectedSection");
    const savedProduct = localStorage.getItem("selectedProduct");

    if (savedSection) {
      setSelectedOption(savedSection);
    }

    if (savedProduct) {
      setSelectedProduct(savedProduct);
    }
  }, []);

  const handleOptionChange = (event, newOption) => {
    if (newOption !== null) {
      setSelectedOption(newOption);
      // Clear the selected product when changing the option
      setSelectedProduct("");
      localStorage.removeItem("selectedProduct");
    }
  };

  const handleSelectTier = (tier) => {
    const productName = tier.title.toLowerCase().replace(/\s+/g, "");
    // Save the selection and product in localStorage
    localStorage.setItem("selectedSection", selectedOption);
    localStorage.setItem("selectedProduct", productName);
    // Update the state
    setSelectedProduct(productName);
  };

  const currentTiers = pricingOptions[selectedOption] || [];

  return (
    <Container className="contact-container">
      <Box sx={{ p: 2, mt: 0, mb: 0, borderRadius: "10px" }}>
        <Paper elevation={1} sx={{ p: 3, mt: 0, mb: 1, borderRadius: "10px" }}>
          <Typography
            component="h1"
            variant="h3"
            align="center"
            color="white"
            gutterBottom
            sx={{
              backgroundColor: "primary.main",
              width: "100%",
              fontWeight: "bold",
              padding: "20px",
              borderRadius: "10px",
            }}
          >
            Pricing
          </Typography>
          <Typography
            variant="h5"
            align="center"
            color="text.secondary"
            component="p"
          >
            Choose the plan that's right for you
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 4,
              mb: 4,
              alignItems: "flex-start",
            }}
          >
            <ToggleButtonGroup
              value={selectedOption}
              exclusive
              onChange={handleOptionChange}
              aria-label="pricing option"
            >
              <ToggleButton value="agents" aria-label="agent connect">
                Agent Connect
              </ToggleButton>
              <ToggleButton value="brokerages" aria-label="brokerage connect">
                Brokerage Connect
              </ToggleButton>
              <ToggleButton
                value="broker"
                aria-label="broker owner/manager connect"
              >
                Broker Owner/Manager Connect
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>

          {selectedOption === "brokerages" && (
            <Typography variant="body2" align="center" sx={{ mt: 2, mb: 2 }}>
              Note: Staff accounts are free. Additional storage costs apply
              beyond the included limit.
            </Typography>
          )}

          {selectedOption === "broker" && (
            <Typography variant="body1" align="center" sx={{ mt: 2, mb: 4 }}>
              Broker Owner/Manager Connect is a free service designed to foster
              collaboration and knowledge sharing among real estate industry
              leaders. Enjoy instant messaging and look forward to video
              messaging capabilities coming soon!
            </Typography>
          )}

          <Grid container spacing={4} alignItems="stretch">
            {currentTiers.map((tier, index) => (
              <Grid
                item
                key={tier.title}
                xs={12}
                sm={
                  selectedOption === "broker"
                    ? 12
                    : tier.title === "Enterprise"
                    ? 12
                    : 6
                }
                md={selectedOption === "broker" ? 12 : 4}
              >
                <ColoredCard colorIndex={index}>
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "baseline",
                        mb: 2,
                      }}
                    >
                      {tier.price === "Free" ? (
                        <Typography
                          component="h2"
                          variant="h3"
                          color="red"
                          fontWeight="bold"
                        >
                          {tier.price}
                        </Typography>
                      ) : (
                        <>
                          <Typography
                            component="h2"
                            variant="h3"
                            color="text.primary"
                          >
                            ${tier.price}
                          </Typography>
                          <Typography variant="h6" color="text.secondary">
                            /mo
                          </Typography>
                        </>
                      )}
                    </Box>
                    <Typography component="h3" variant="h4" align="center">
                      {tier.title}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      align="center"
                      sx={{ mb: 2 }}
                    >
                      {tier.description}
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                      {tier.features.map((feature) => (
                        <Typography
                          component="li"
                          variant="subtitle1"
                          align="left"
                          key={feature}
                          sx={{ mb: 1 }}
                        >
                          {feature}
                        </Typography>
                      ))}
                    </Box>
                  </CardContent>
                  <CardActions>
                    <ColoredButton
                      fullWidth
                      variant={tier.buttonVariant}
                      colorIndex={index}
                      textColor={tier.buttonTextColor}
                      component="a"
                      href={`/signup?${tier.buttonLink}`}
                      onClick={() => handleSelectTier(tier)}
                    >
                      {tier.buttonText}
                    </ColoredButton>
                  </CardActions>
                </ColoredCard>
              </Grid>
            ))}
          </Grid>
        </Paper>
      </Box>
    </Container>
  );
}

export default Pricing;
